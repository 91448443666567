import React, { createContext, useContext, useState } from 'react';
const CurrentContext = createContext();

export const useCurrentContext = () => {
  const ctx = useContext(CurrentContext);
  if (!ctx) {
    throw new Error('useCurrentContext must be used within a CurrentContext');
  }
  return ctx;
};

export const CurrentContextProvider = ({ children }) => {
  const [currentState, setCurrentState] = useState(0);
  const [currentSelected, setCurrentSelected] = useState({});
  const [canContinue, setCanContinue] = useState(false);
  const [Consumption, setConsumption] = useState({});
  const [TotalArea, setTotalArea] = useState();
  const [Email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [discount, showDiscount] = useState(false);
  const [fetchedData, setFetchedData] = useState({
    "Erdgas":
      { "Unit": "kWh", "EmissionFactor": 0.18139, "Heizwert": 1, "Brennwert": 1.11 },
    "Flüssiggas":
      { "Unit": "L", "EmissionFactor": 0.2358, "Heizwert": 6.57, "Brennwert": 1 },
    "Heizöl":
      { "Unit": "L", "EmissionFactor": 0.2664, "Heizwert": 10.0461, "Brennwert": 1 },
    "Fernwärme":
      { "Unit": "kWh", "EmissionFactor": 0.2553, "Heizwert": 1, "Brennwert": 1 }
  });
  return (
    <CurrentContext.Provider
      value={{
        currentState, setCurrentState,
        currentSelected, setCurrentSelected,
        canContinue, setCanContinue,
        Consumption, setConsumption,
        TotalArea, setTotalArea,
        Email, setEmail,
        loading, setLoading,
        discount, showDiscount,
        fetchedData, setFetchedData,
      }}
    >
      {children}
    </CurrentContext.Provider>
  )
};

