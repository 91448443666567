import React from 'react'
import './style.css';

import FirstStep from '../Steps/FirstStep';
import SecondStep from '../Steps/SecondStep';
import ThirdStep from '../Steps/ThirdStep';
import FourthStep from '../Steps/FourthStep'
import FifthStep from '../Steps/FifthStep';
import SixthStep from '../Steps/SixthStep';

import { useCurrentContext } from '../Context/Context';

const SelectionChoice = () => {
    const { currentState } = useCurrentContext();
    switch (currentState) {
        case 1:
            return <SecondStep />
        case 2:
            return <ThirdStep />
        case 3:
            return <FourthStep />
        case 4:
            return <FifthStep />
        case 5:
            return <SixthStep />
        default:
            return <FirstStep />
    }
}

export default SelectionChoice;