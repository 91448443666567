import React, { useEffect } from 'react'
import Header from '../Header/header'
import Footer from "../Footer/footer";
import Stepper from "../Stepper/stepper"
import SelectionChoice from "../SelectionChoice/selectionChoice"
import { ContinueButton } from "../Button/button"
import LoadingOverlay from 'react-loading-overlay'
import HTMLReactParser from 'html-react-parser';
import { useCurrentContext } from '../Context/Context';
import { PageTracker, SelectionTracker } from '../Tracker/tracker';


const Layout = () => {
  const { loading, currentState, currentSelected } = useCurrentContext();
  // scroll app to top on state change
  useEffect(() => {
    PageTracker(currentState);
    window.scrollTo(0, 0);
  }, [currentState]);

  useEffect(() => {
    SelectionTracker(currentSelected, currentState);
  }, [currentSelected]);

  useEffect(() => {
    if (currentState === 4) {
      document.body.style.background = '#d9d9d980'
      document.getElementsByClassName('main')[0].style.height = 'auto'
    } else {
      document.body.style.background = 'white';
      document.getElementsByClassName('main')[0].style.height = '100vh'
    }
  }, [currentState]);
  
  return (
    <LoadingOverlay active={loading}
      spinner
      text={HTMLReactParser('<h3 class="univers-roman" style="color: white">Prognose wird berechnet...</h3><p>Dies kann bis zu 10 Sekunden dauern</p>')}>
      <div className='main'>
        <Header></Header>
        <div className="App d-flex flex-column align-items-center container mb-5" translate="no">
          {currentState > 3
            ? ('')
            : (<Stepper></Stepper>)}
          <SelectionChoice></SelectionChoice>
          {currentState === 3 || currentState === 5
            ? ('')
            : (<ContinueButton></ContinueButton>)}
        </div>
        <Footer></Footer>
      </div>
    </LoadingOverlay>
  );
}

export default Layout;

