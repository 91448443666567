import React, { useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useCurrentContext } from '../Context/Context';
import { getTenantData, getDiscountedTenantData, getLandlordData, getDiscountedLandlordData } from '../Calculation/Calculation';
import { ContinueButton } from '../Button/button';
import InfoModal from '../Modal/modal';


import ImagePdfFront from '../../assets/images/ImagePdfFront.png';
import ImageFinalThree from '../../assets/images/ImageFinalThree.jpg';
import ImagePdfThreeOne from '../../assets/images/pdfThreeOne.png';

import ReportSection from '../ReportSection/ReportSection';

const SixthStep = () => {
  const { currentState, currentSelected, setCanContinue, Consumption, TotalArea, discount, showDiscount, fetchedData } = useCurrentContext();
  const vat = currentSelected.val % 2 === 0 ? 1.07 : 1.19;
  const tenantData = getTenantData(currentSelected, Consumption, fetchedData, vat);
  const discountedTenantData = getDiscountedTenantData(currentSelected, Consumption, fetchedData, vat);
  const landlordData = getLandlordData(tenantData, currentSelected, Consumption, TotalArea, fetchedData);
  const discountedLandlordData = getDiscountedLandlordData(discountedTenantData, currentSelected, Consumption, TotalArea, fetchedData);

  const CalculateYearlyCost = () => {
    const sum = landlordData.reduce((partial, current) => {
      return parseFloat(partial) + parseFloat(current)
    }, [0])
    return parseFloat(sum).toFixed(2)
  };

  const CalculateDiscountedYearlyCost = () => {
    const sum = discountedLandlordData.reduce((partial, current) => {
      return parseFloat(partial) + parseFloat(current)
    }, [0])
    return parseFloat(sum).toFixed(2)
  };

  useEffect(() => {
    setCanContinue(true);
  }, [])

  const showDiscountChart = () => {
    showDiscount(!discount);
  }

  return (
    <div className='mt-5 final-page container'>
      <Row>
        <Col md={6} className='pe-5'>
          <h1 className='univers-bold'>
            Energie sparen mit <br /> Techem
          </h1>
          <div className='grey-text mb-3'>
            Steigern Sie durch Energieeffizienz den Wert Ihrer Immobilie.
            Beauftragen Sie jetzt unsere unterjährige Verbrauchsinformation, um Ihren Energieverbrauch um 10 % zu senken.
            Oder werfen Sie einen Blick in unsere Energiespartipps, um unkompliziert zu sparen.
            Und mit unserem Heizungscheck Online können Sie herausfinden, wie fit Ihre Heizungsanlage ist.
          </div>
        </Col>
        <Col md={6} style={{ zIndex: 2 }}>
          <img className='bordered-img h-480' src={ImagePdfFront} alt='front-img'></img>
        </Col>
      </Row>
      <Row className='justify-content-center text-center mt-5 mb-5'>
        <Col md={10}>
          <h2 className='univers-bold'>Energie sparen mit Techem</h2>
          <div className='grey-text'>Kosten kennen. Klug planen. Mit Weitblick investieren. Mit &nbsp;Techem erhalten Sie nicht nur eine Einschätzung
            über die voraussichtlichen CO<sub>2</sub>-Kosten, die Sie erwarten. Wir unterstützen Sie zudem gerne mit smarten Lösungen beim Energie sparen.
            Verschaffen Sie sich hier einen einfachen Überblick über unser Angebot plus voraussichtlicher Einsparpotenziale!</div>
        </Col>
      </Row>
      <Row className='justify-content-center mt-5'>
        <Col>
          <ReportSection></ReportSection>
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col lg={6} className='mb-3 remove-font'>
          <div>
            <div className='univers-bold'>
              <div className='d-inline'>10 Jahre Gesamtkosten</div> <InfoModal currentState={currentState} InfoToShow={4}></InfoModal>

            </div>
            <div className='d-flex row'>
              <div className={`stepper-text mt-3 me-3 fs-40 col-6 d-flex gap-2 align-items-baseline`}>
                <div className={`${discount ? 'cross-text' : ''}`} style={{ whiteSpace: "nowrap" }}>
                  {parseFloat(CalculateYearlyCost()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € </div>
                <div className='d-inline' style={{ fontSize: "13px", whiteSpace: "nowrap" }}>inkl. MwSt.</div>
              </div>
              {discount
                ? (<div className='d-flex mt-3 col-6' style={{ whiteSpace: "nowrap" }}>
                  <div className='discount-text stepper-text fs-40'>{parseFloat(CalculateDiscountedYearlyCost()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € <div className='d-inline' style={{ fontSize: "13px" }}>inkl. MwSt.</div></div>
                  <InfoModal currentState={currentState} InfoToShow={5}></InfoModal>
                </div>)
                : ('')}
            </div>
          </div>
        </Col>
        <Col lg={6} className='d-flex justify-content-xl-end align-items-end'>
          <Button variant='continue' className='btn-heizung rounded-pill' onClick={showDiscountChart}>So viel können Sie mit der UVI sparen*</Button>
        </Col>
      </Row>
      <Row className='justify-content-center mb-5'>
        <Col md={10}>
          <div className='grey-text'>
            Durch mehr  Transparenz mit der EED-Verbrauchsinfo von  Techem können Mieter sowie Eigentümer ihren Verbrauch effizient senken - und damit auch die Kosten!
            Denn: Nur wer seinen Verbrauch kennt, kann gezielt Energie sparen.
            Aktivieren Sie noch heute die EED-Verbrauchsinfo!
          </div>
        </Col>
      </Row>
      <Row className='text-center mb-5'>
        <div>
          <ContinueButton></ContinueButton>
        </div>
      </Row>
      <Row className='align-items-center justify-content-between'>
        <Col md={5}>
          <h2 className='univers-bold'>
            Unkompliziert Abgaben reduzieren mit unseren Energiespartipps
          </h2>
          <div className='grey-text mb-3'>
            Damit Ihre Mieter ihren Verbrauch noch einfacher reduzieren können, haben wir sieben einfache
            Tipps zum Thema „Clever heizen“ zusammengestellt. Hier erfahren Sie, was die optimale Raumtemperatur ist,
            wie man richtig lüftet und wie sich bis zu 50 kg CO<sub>2</sub> im Jahr - quasi im Schlaf - sparen lassen!
          </div>
        </Col>
        <Col md={6} className='mb-3'>
          <img className='bordered-img' src={ImageFinalThree} alt='ImageFinalTwo' />
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Button href='https://www.techem.com/de/de/info-und-service/mieterinfos?CID=referrer:organic:co2rechner:button:co2rechner:heiztipps:co2:targeting:nr:2092022#energiespartipps'
          target='_blank' variant='continue' className='col-md-4 rounded-pill mt-5 mb-5'>Gleich reinlesen</Button>
      </Row>
      <Row className='align-items-center justify-content-between flex-column-reverse flex-md-row'>
        <Col md={6} className='mb-3'>
          <img className='bordered-img' src={ImagePdfThreeOne} alt='ImageFinalTwo' />
        </Col>
        <Col md={5}>
          <h2 className='univers-bold'>
            Heizungscheck Online
          </h2>
          <div className='grey-text mb-3'>
            Damit eine Heizungsanlage effizient läuft, müssen ihre Komponenten richtig eingestellt sein.
            Doch wie fit ist Ihre Heizungsanlage wirklich? Mit wenigen Fragen können Sie einen Eindruck davon bekommen,
            ob sich die Effizienz Ihrer Heizungsanlage verbessern lässt, um Energie zu sparen.
            Machen Sie jetzt den Check mit dem Heizungscheck Online.
          </div>
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Button href='https://www.techem.com/de/de/energieeffizienz/heizungscheck-online?CID=referrer:organic:co2rechner:button:co2rechner:heizungscheckrechner:co2:targeting:st:5122022'
          target='_blank' variant='continue' className='col-md-4 rounded-pill mt-5 mb-5'>Zum Heizungscheck Online</Button>
      </Row>
      <Row className='univers-it d-inline' style={{ 'color': 'gray' }}>
        *Mit der unterjährigen Verbrauchsinfo können Mieterinnen und Mieter laut Untersuchungen etwa 10 % ihres Heizungsverbrauchs senken (
        <a className='ps-0 pe-0'
          style={{ "width": "fit-content", 'color': 'red' }}
          href='https://www.dena.de/newsroom/meldungen/2017/dena-modellvorhaben-mehrheit-der-mieter-profitiert-von-informationen-zum-waermeverbrauch/'
          target='_blank' rel='noopener noreferrer'>dena-Studie
        </a>).
      </Row>
    </div>
  )
}

export default SixthStep;