import React from "react";
import { Button } from "react-bootstrap";
import "./style.css";
import IconBack from '../../assets/icons/IconBack.svg';
import axios from 'axios';
import { apiUrl, formId } from '../../constants'

import { useCurrentContext } from '../Context/Context';
import html2canvas from "html2canvas";

const handleNext = (props) => () => {
    if (props.currentState === 3) {
        props.setLoading(true);
        const input = document.getElementById('pdf-section');
        setTimeout(() => {
            input.style.display = 'flex'
        }, 1000);
        setTimeout(() => {
            html2canvas(input).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const data = new FormData();
                const formID = formId();
                const apiURI = apiUrl() + '/uploadPdf'
                let userData
                if (formID === 1609) {
                    userData = {
                        "formId": formID,
                        "input": [
                            {
                                "leadFormFields": {
                                    "email": props.Email,
                                    "option1": props.currentSelected.name,
                                    "option2": props.Consumption.Value,
                                    "option3": props.Consumption.Unit,
                                    "option4": props.TotalArea,
                                    "mktoPreOptIn": true,
                                    "mktoConsentHistory": "T1A"
                                }
                            }
                        ]
                    }
                } else {
                    userData = {
                        "formId": formID,
                        "input": [
                            {
                                "leadFormFields": {
                                    "email": props.Email,
                                    "option1": props.currentSelected.name,
                                    "option2": props.Consumption.Value,
                                    "option3": props.Consumption.Unit,
                                    "option4": props.TotalArea,
                                    "preOptIn": true,
                                    "mktoConsentHistory": "T1A"
                                }
                            }
                        ]
                    }
                }
                data.append('image', imgData);
                data.append('userData', JSON.stringify(userData));
                const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
                axios.post(apiURI, data, config).then(response => {
                console.log(response);
                }).catch((error) => {
                return JSON.stringify(error);
                });
                props.setLoading(false);
                props.setCurrentState(props.currentState + 1);
            }).catch((error) => {
                console.log(error);
            });
        }, 2000)

    } else if (props.currentState === 4) {
        window.open('https://vermieter.techem.de/')
    }

    else if (props.currentState === 5) {
        window.open('https://www.techem.com/de/de/immobilienservices/verbrauchsinformation?CID=referrer:organic:co2rechner:button:co2rechner:verbrauchsinfo:co2:targeting:nr:209202', '_blank');
    } else {
        props.setCurrentState(props.currentState + 1)
        props.setCanContinue(false)
    }
}

const handlePrevious = (props) => () => {
    props.setCurrentState(props.currentState - 1)
    props.setCanContinue(false)
}

const ContinueButton = () => {
    const props = useCurrentContext();
    let BtnName, customClass
    if (props.currentState === 3) {
        BtnName = 'Jetzt Prognose anzeigen'
    } else if (props.currentState === 4) {
        customClass = 'move-top'
        BtnName = 'Direkt zum Angebot'
    } else if (props.currentState === 5) {
        BtnName = 'Mehr zur unterjährigen Verbrauchsinformation'
    }
    else {
        BtnName = 'Weiter'
    }
    return (
        <>
            {props.canContinue
                ? (props.currentState === 4
                    ? (
                        <div className={`${customClass ? customClass : ''}`}>
                            <Button variant="continue" className="rounded-pill" onClick={handleNext(props)}>{BtnName}</Button>
                            <Button href="https://www.techem.com/de/de/info-und-service/kontakt/kontaktformular-neukunden?thema=Heizkostenabrechnung" variant="continue" className="rounded-pill ms-5">Jetzt beraten lassen</Button>
                        </div>
                    )
                    : <Button variant="continue" className={`rounded-pill ${customClass ? customClass : ''}`} onClick={handleNext(props)}>{BtnName}</Button>
                )
                : <Button variant="continue" className="rounded-pill" disabled>{BtnName}</Button>}
        </>
    );
}

const BackButton = () => {
    const props = useCurrentContext();
    return (
        props.currentState > 4
            ? ('') :
            (<button className={`btn-back univers-bold 
            ${props.currentState === 3 ? 'col-1 p-0' : ''}
             ${props.currentState === 4 ? 'grey-box-alt' : ''}`}
                onClick={handlePrevious(props)}>
                <img className={props.currentState < 3 ? ('btn-back-offset') : ('')} src={IconBack} alt='back-icon' />
                {props.currentState === 4 ? 'Zurück' : ''}
            </button>)

    )
}

export { ContinueButton, BackButton, handleNext }