import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useCurrentContext } from '../Context/Context';
import { TotalEmission, CalculateShare, getTenantData, getLandlordData } from '../Calculation/Calculation';
import Chart from '../Chart/Chart'
import InfoModal from '../Modal/modal';

import IconFlussiggas from '../../assets/icons/IconFlussiggas.svg'
import IconErdgas from '../../assets/icons/IconErdgas.svg'
import IconHeizol from '../../assets/icons/IconHeizol.svg'
import IconFernwarme from '../../assets/icons/IconFernwarme.svg'
import IconHouse from '../../assets/icons/IconHouse.svg'
import IconCloud from '../../assets/icons/IconCloud.svg'

const ReportSection = () => {
  const { currentState, currentSelected, Consumption, TotalArea, discount, showDiscount, fetchedData } = useCurrentContext();
  const vat = currentSelected.val % 2 === 0 ? 1.07 : 1.19;
  const tenantData = getTenantData(currentSelected, Consumption, fetchedData, vat);
  const landlordData = getLandlordData(tenantData, currentSelected, Consumption, TotalArea, fetchedData);
  const CalculateYearlyCost = () => {
    const sum = landlordData.reduce((partial, current) => {
      return parseFloat(partial) + parseFloat(current)
    }, [0])
    return parseFloat(sum).toFixed(2)
  };

  let selectedIcon
  if (currentSelected.val === 1) {
    selectedIcon = IconFlussiggas
  } else if (currentSelected.val === 2) {
    selectedIcon = IconErdgas
  } else if (currentSelected.val === 3) {
    selectedIcon = IconHeizol
  } else if (currentSelected.val === 4) {
    selectedIcon = IconFernwarme
  }

  // get element when visible
  const useOnScreen = (ref) => {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [])

    return isIntersecting
  }


  // scroll scrollable element when visible
  const visible = useRef()
  const isVisible = useOnScreen(visible)
  if (isVisible) {
    visible.current.scrollBy({ left: 200, behavior: 'smooth' })
    setTimeout(() => {
      visible.current.scrollBy({ left: -200, behavior: 'smooth' })
    }, 250);
  }

  return (
    <div className='report-section container'>
      <div id='report-content'>
        <Row className={`mb-3 d-flex row justify-content-between`}>
          <Col md={6} lg={6} xl={4} className='p-0 pe-md-3 mb-3 mb-xl-0'>
            <div className='white-box p-3 d-flex justify-content-between align-items-end'>
              <div className='d-flex align-items-center flex-column'>
                <div className='choice-item'>
                  <img src={selectedIcon} alt='item-icon' />
                  <p className='choice-text'>{currentSelected.name}</p>
                </div>
                <div className='univers-bold'>{parseFloat(Consumption.Value).toLocaleString('de-DE')} {Consumption.Unit}</div>
              </div>
              <div className='d-flex align-items-center flex-column'>
                <div className='choice-item'>
                  <img src={IconHouse} alt='house-icon' />
                  <p className='choice-text'>Wohnfläche</p>
                </div>
                <div className='univers-bold'>{TotalArea} m<sup>2</sup></div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={6} xl={3} className='p-0 mb-3 mb-xl-0'>
            <div className='white-box p-3 d-flex flex-column justify-content-end align-items-center blue-border-dashed'>
              <div className='choice-item'>
                <img src={IconCloud} alt='house-icon' />
                <p className='choice-text text-center'>CO<sub>2</sub>-Emission <span className='d-inline-block'>(10 Jahre)</span></p>
              </div>
              <div className='univers-bold d-flex align-items-end'><div className='d-inline'>{(parseFloat(((TotalEmission(currentSelected, Consumption, fetchedData) / 1000) * 10).toFixed(2))).toLocaleString('de-DE')} t</div>
                <InfoModal currentState={currentState} InfoToShow={3}></InfoModal></div>
            </div>
          </Col>
          <Col lg={12} xl={5} className='p-0 ps-xl-3'>
            <div className='white-box p-3 d-flex blue-border'>
              <div className='d-flex flex-column w-100 mt-4 justify-content-end'>
                <div className='stepper-text fs-40 text-end w-100'>{parseFloat(CalculateYearlyCost()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €{(currentSelected.val === 2 || currentSelected.val === 4) ? '*' : ''}</div>
                <div className='d-flex justify-content-between'>
                  <div>
                    <div className='d-inline univers-bold'>Gesamtkosten</div> <InfoModal currentState={currentState} InfoToShow={4}></InfoModal></div>
                  <div style={{ fontSize: "13px" }}>inkl. MwSt. </div>
                </div>
                <div>(10 Jahre)</div>
              </div>
            </div>
          </Col>
        </Row>
        {currentSelected.val === 2 &&
          <Row className='mb-4 mt-4 ps-3 pe-3' style={{ fontSize: "12px" }}>
            <div>*Es sind CO<sub>2</sub>-Rechner am Markt, die die CO<sub>2</sub>-Menge mit einem heizwertbezogenen CO<sub>2</sub>-Faktor berechnen. Da die Energiemenge Ihrer Versorgerrechnung aber brennwertbezogen errechnet wurde, muss die CO<sub>2</sub>-Menge auch brennwertbezogen errechnet werden. Dies ist in unserem Rechner bereits berücksichtigt. In Rechnern mit einem heizwertbezogenen CO<sub>2</sub>-Faktor müssen Sie daher bitte noch die Energiemenge mit dem Korrekturfaktor 0,903 multiplizieren.</div>
          </Row>
        }

        {currentSelected.val === 4 &&
          <Row className='mb-4 mt-4 ps-3 pe-3' style={{ fontSize: "12px" }}>
            <div>*Die CO<sub>2</sub>-Kosten haben wir auf Basis der Zertifikatskosten im Europäischen Emissionshandel errechnet. Veröffentlicht vom Umweltbundesamt am 19.01.2024.</div>
          </Row>
        
        }
        <Row className={`mb-3 ${currentState === 4 ? 'white-box pt-5 pe-5 ps-3 pe-3' : ''}`}>
          <Row>
            <Col xl={7}>
              <div className='d-flex flex-column flex-sm-row align-items-md-center'>
                <div className='univers-bold fs-22 pe-1'>Techem <span style={{ whiteSpace: 'nowrap' }}>CO<sub>2</sub>-Kostenprognose:</span></div>
                <div className='d-inline align-items-center'>Ihr Anteil beträgt voraussichtlich <span className='d-inline-block'>{parseFloat(discount ? CalculateShare(currentSelected, Consumption, TotalArea, 0.9, fetchedData) : CalculateShare(currentSelected, Consumption, TotalArea, 1, fetchedData)).toLocaleString('de-DE')} %
                  <InfoModal currentState={currentState} InfoToShow={6} styleName='move-info-top'></InfoModal>
                </span>
                </div>
              </div>
            </Col>
            <Col xl={5} className='mt-3 mt-xl-0'>
              <Row className='d-flex align-items-center'>
                <Col xl={4} sm={3}>
                  <Row className='d-flex justify-content-xxl-end align-items-center'>
                    <Col xs={1} className='me-3'><span className='dot tenant-dot'></span></Col>
                    <Col xs={3} className='ps-0'><span className='black-text'>Mietende</span></Col>
                  </Row>
                </Col>
                <Col xl={8} lg={4} md={6} className='tenant-text d-flex justify-content-xl-end'>
                  <div>
                    {parseFloat(100 - (discount ? CalculateShare(currentSelected, Consumption, TotalArea, 0.9, fetchedData) : CalculateShare(currentSelected, Consumption, TotalArea, 1, fetchedData))).toLocaleString('de-DE')} % Anteil an der CO<sub>2</sub>-Abgabe
                  </div>
                </Col>

              </Row>
              <Row className='d-flex align-items-center'>
                <Col xl={4} sm={3}>
                  <Row className='d-flex justify-content-xxl-end align-items-center'>
                    <Col xs={1} className='me-3'><span className='dot landlord-dot'></span></Col>
                    <Col xs={3} className='ps-0'><span className='univers-bold black-text'>Vermietende</span></Col>
                  </Row>
                </Col>
                <Col xl={8} lg={4} md={6} className='landlord-text d-flex justify-content-xl-end'>
                  <div>
                    {parseFloat(discount ? CalculateShare(currentSelected, Consumption, TotalArea, 0.9, fetchedData) : CalculateShare(currentSelected, Consumption, TotalArea, 1, fetchedData)).toLocaleString('de-DE')} % Anteil an der CO<sub>2</sub>-Abgabe
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div ref={visible} className='d-flex justify-content-lg-center' style={{ "overflowX": "auto" }}>
            <div id='bar-canvas' style={{ "postion": "relative", "minWidth": "800px" }}>
              <Chart></Chart>
            </div>
          </div>
        </Row>
        {currentState === 5
          ? ('')
          : (<Row className='text-center white-box pt-5 ps-3 pe-3 extra-space'>
            <h3 className='stepper-text'>Ihr CO<sub>2</sub>-Kostenanteil beträgt voraussichtlich <span style={{ whiteSpace: "nowrap" }}>{parseFloat(CalculateShare(currentSelected, Consumption, TotalArea, 1, fetchedData)).toLocaleString('de-DE')} %</span></h3>
            <div>
              Dieser Wert ergibt sich aus der Energiebilanz Ihres Gebäudes.
              Je besser diese ist, desto niedriger wird voraussichtlich Ihr Anteil an den CO<sub>2</sub>-Kosten als Vermieter oder Vermieterin sein.
              Es handelt sich hierbei um eine unverbindliche Prognose, da sich Ihr Energieverbrauch in der Zukunft ändern kann.
              <br />
              <br />
              CO<sub>2</sub>-Kosten rechtskonform in der Heizkostenabrechnung auf die Mietenden umlegen? Mit Techem kein Problem.
            </div>
          </Row>)}
      </div>
    </div>
  )
}

export default ReportSection;