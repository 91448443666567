import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

import IconHouse from '../../assets/icons/IconHouse.svg';
import { useCurrentContext } from '../Context/Context';

const ThirdStep = () => {
    const { currentState, setCurrentState, setCanContinue, TotalArea, setTotalArea } = useCurrentContext();
  useEffect(() => {
      if (TotalArea) {
          setCanContinue(true);
      }
  });
  const handleInputChange = () => (event) => {
      setTotalArea(event.target.value)
      if (event.target.value.length > 0) {
          setCanContinue(true)
      } else {
          setCanContinue(false)
      }
  }
  const handleSubmit = e => {
      e.preventDefault();
      setCurrentState(currentState + 1)
      setCanContinue(false)
  };
  return (
      <div className='d-flex align-items-center mb-5 choice-container'>
          <div className='grid-row'>
              <div className='choice-item'>
                  <img src={IconHouse} alt='item-icon' />
                  <p className='choice-text mt-2 mb-0'>Wohnfläche</p>
              </div>
              <Form onSubmit={handleSubmit}>
                  <Form.Group className='d-flex align-items-center' controlId="formInput">
                      <Form.Control type="number"
                          value={TotalArea ? TotalArea : ''}
                          placeholder="Wert eintragen"
                          className={`pt-2 pe-4`} onChange={handleInputChange()} autoComplete='off' />
                      <span className='unit unit-meter'>m<sup>2</sup></span>
                  </Form.Group>
              </Form>
          </div>
      </div>
  )
}

export default ThirdStep;