import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useCurrentContext } from '../Context/Context';
import { labels, getTenantData, getDiscountedTenantData, getLandlordData, getDiscountedLandlordData } from '../Calculation/Calculation';


const Chart = () => {
  const { currentSelected, Consumption, TotalArea, currentState, discount, fetchedData } = useCurrentContext();
  const vat = currentSelected.val % 2 === 0 ? 1.07 : 1.19;
  const tenantData = getTenantData(currentSelected, Consumption, fetchedData, vat);
  const discountedTenantData = getDiscountedTenantData(currentSelected, Consumption, fetchedData, vat);
  const landlordData = getLandlordData(tenantData, currentSelected, Consumption, TotalArea, fetchedData);
  const discountedLandlordData = getDiscountedLandlordData(discountedTenantData, currentSelected, Consumption, TotalArea, fetchedData);
  // add dotted line and label above line for tenant chart
  const floatingLabels = {
    id: 'floatingLabels',
    beforeDatasetsDraw(chart) {
      const chart1 = chart.getDatasetMeta(1);
      const chart2 = chart.getDatasetMeta(2);

      let chartToSelect;
      if (chart2.label) {
        chartToSelect = chart2
      } else {
        chartToSelect = chart1
      }

      const { ctx, chartArea: { left }, scales: { x } } = chart;
      for (let i = 0; i < chartToSelect.data.length; i++) {
        // if (i > 1 && (chart.getDatasetMeta(datasetToSelect).data[i].$context.raw === chart.getDatasetMeta(datasetToSelect).data[i - 1].$context.raw)) {
        //     break
        // }

        // show first second and last
        if (i > 2 && i < chartToSelect.data.length - 1) {
          continue
        }
        const xPostion = left - 40
        const yPostion = chartToSelect.data[i].y

        ctx.save()
        ctx.strokeStyle = 'rgba(0, 155, 180, 1)'
        ctx.setLineDash([2, 5])
        ctx.lineWidth = "2"
        ctx.beginPath()
        ctx.moveTo(x.getPixelForValue(i) + ((x.getPixelForValue(i + 1) - x.getPixelForValue(i)) / 2 - 6), yPostion);
        ctx.lineTo(xPostion, yPostion);
        ctx.stroke();
        ctx.fillText(parseFloat(chartToSelect.data[i].$context.raw).toLocaleString('de-DE') + " €", xPostion, yPostion - 10)
      }
    }
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ChartDataLabels,
    floatingLabels
  )

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: 'CO2 Chart',
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          padding: 30,
          font: {
            weight: 'bold',
          }
        }
      },
      y: {
        display: false,
        stacked: false,
        grid: {
          drawOnChartArea: false
        },

      },
    },
    layout: {
      padding: {
        top: 20,
        left: 40
      }
    }
  }

  // based on state add discound dataset
  let dataset
  if (currentState === 5 && discount) {
    dataset = [
      {
        label: 'Discount',
        data: discountedLandlordData,
        backgroundColor: 'rgba(226, 6, 19, 1)',
        datalabels: {
          color: 'white',
          anchor: 'end',
          align: 'bottom',
          font: {
            family: 'Univers B'
          },
          display: 'true',
          formatter: (value) => { return parseFloat(value).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' }
        },
        barPercentage: 0.9,
        categoryPercentage: 1
      },
      {
        label: 'Vermietende',
        data: landlordData,
        backgroundColor: 'rgba(0, 155, 180, 1)',
        datalabels: {
          color: 'black',
          anchor: 'end',
          align: 'bottom',
          font: {
            family: 'Univers B'
          },
          display: 'auto',
          formatter: (value) => { return parseFloat(value).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' }
        },
        barPercentage: 0.9,
        categoryPercentage: 1
      },
      {
        label: 'Mietende',
        data: discountedTenantData,
        backgroundColor: 'rgba(0, 155, 180, 0.15)',
        datalabels: {
          display: false
        },
        barPercentage: 0.9,
        categoryPercentage: 1
      },
    ]
  } else {
    dataset = [
      {
        label: 'Vermietende',
        data: landlordData,
        backgroundColor: 'rgba(0, 155, 180, 1)',
        datalabels: {
          color: 'black',
          anchor: 'end',
          align: 'bottom',
          font: {
            family: 'Univers B'
          },
          formatter: (value) => { return parseFloat(value).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' }
        },
        barPercentage: 0.9,
        categoryPercentage: 1
      },
      {
        label: 'Mietende',
        data: tenantData,
        backgroundColor: 'rgba(0, 155, 180, 0.15)',
        datalabels: {
          display: false
        },
        barPercentage: 0.9,
        categoryPercentage: 1
      },
    ]
  }

  const chartData = {
    labels: labels,
    datasets: dataset,
  };

  return (
    <Bar options={chartOptions} data={chartData} />
  )

}


export default Chart;