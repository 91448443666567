import React from 'react';
import './style.css';
import techemLogo from '../../assets/icons/Techem-Logo.svg'

const Header = ({currentState}) => {
    return (
        <div style={{"background": "white"}}>
            <div className={`container header__components`}>
                <div className="header__left pt-3 pb-3">
                    <span className="header__logo">
                        <a href="https://www.techem.com/de/de?CID=referrer:organic:co2rechner:logo:co2rechner:home:co2:targeting:nr:6092022" target="_self">
                            <img className="header__logo-image" src={techemLogo} alt='techem-logo' />
                        </a>
                    </span>
                    <div className="header__target">
                    </div>
                </div>
                <div className="header__right">
                    <div className="header__portal">
                    </div>
                    <div className="header__lang-nav hidden">
                    </div>
                </div>
            </div>
            <div className={`text-stage-content ${currentState === 5 ? 'move-bot' : ''}`}>
                <div className="cmp-text-stage aem-Grid aem-Grid--12 aem-Grid--default--12">
                </div>
            </div>
        </div>
    )
}

export default Header