import React, { useEffect } from 'react';

import IconFlussiggas from '../../assets/icons/IconFlussiggas.svg'
import IconErdgas from '../../assets/icons/IconErdgas.svg'
import IconHeizol from '../../assets/icons/IconHeizol.svg'
import IconFernwarme from '../../assets/icons/IconFernwarme.svg'
import { useCurrentContext } from '../Context/Context';

const Selection = (props) => {
  return (
    <div className='choice-item'>
      <img src={props.imageUrl} alt={props.name} />
      <p className='choice-text mt-2'>{props.name}</p>
      <input type='radio'
        name='choice-item'
        value={props.name}
        id={`choice-${props.name}`}
        data-id={props.dataId}
        onChange={props.changeHandler}
        defaultChecked={props.currentSelected.val === parseInt(props.dataId) ? true : false}
      >
      </input>
    </div>
  )
}

const FirstStep = () => {
  const { currentSelected, setCurrentSelected, canContinue, setCanContinue } = useCurrentContext();
  useEffect(() => {
    if (currentSelected.val) {
      setCanContinue(true);
    }
  }, []);

  const handleRadioChange = () => (event) => {
    const selectedOption = event.target.getAttribute('data-id')
    setCurrentSelected({ val: parseInt(selectedOption), name: event.target.value })
    setCanContinue(true)
  }

  return (
    <>
      <div className='choice-container mb-3'>
        <Selection name='Erdgas'
          dataId='2'
          imageUrl={IconErdgas}
          currentSelected={currentSelected}
          changeHandler={handleRadioChange()} />
        <Selection name='Flüssiggas'
          dataId='1'
          imageUrl={IconFlussiggas}
          currentSelected={currentSelected}
          changeHandler={handleRadioChange()} />
        <Selection name='Heizöl'
          dataId='3'
          imageUrl={IconHeizol}
          currentSelected={currentSelected}
          changeHandler={handleRadioChange()} />
        <Selection name='Fernwärme'
          dataId='4'
          imageUrl={IconFernwarme}
          currentSelected={currentSelected}
          changeHandler={handleRadioChange()} />
      </div>
    </>
  )
}

export default FirstStep;