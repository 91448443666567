import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import htmlParser from 'html-react-parser';

import './style.css'

import InfoIcon from '../../assets/icons/InfoIcon.svg'
import InfoHover from '../../assets/icons/InfoHover.svg'

const InfoModal = (props) => {
    const [show, setShow] = useState(false)
    const [hover, setHover] = useState(false)

    const activeHover = () => {
        setHover(true)
    }

    const inactiveHover = () => {
        setHover(false)
    }

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const modalHeadlineText = ['Mit welcher Energiequelle wird geheizt?',
        'Wie kann ich meinen Brennstoffverbrauch ermitteln?',
        'Wie groß ist die Wohnfläche Ihrer Immobilie?',
        '',
        '',
        '']

    const modalBodyText = [`<p>Mit dieser Angabe können wir Ihnen im nächsten Schritt die richtige Einheit zur Berechnung anbieten.
     Nutzen Sie eine Pelletheizung oder Wärmepumpe, so fallen keine CO<sub>2</sub>-Kosten für Sie an.</p>`,
        `<p>Den jährlichen Brennstoffverbrauch können Sie der Jahresrechnung Ihres Energieversorgungsunternehmens entnehmen.</p>`,
        `<p>Die CO<sub>2</sub>-Abgabe ist für alle Wohngebäude einschließlich Wohn-, Alten- und Pflegeheime sowie Gebäude mit gemischter
         Nutzung vorgesehen. Die Wohnfläche ist die Summe der Grundflächen aller Wohnräume.</p>`,
        `<p>Die voraussichtliche CO<sub>2</sub>-Emission Ihres Gebäudes wurde gemäß Ihrer Angabe des aktuellen
         Energieverbrauchs für einen Zeithorizont von 10 Jahren berechnet.</p>`,
        `<p>Damit Sie umfassend planen können, berechnen wir für Sie die voraussichtliche CO<sub>2</sub>-Abgabe mit einem
        Zeithorizont von 10 Jahren.
        Die Summe beinhaltet nur die Kosten, die Vermietende tragen müssen.
        Der CO<sub>2</sub>-Preis pro Tonne wurde bislang von Jahr zu Jahr erhöht, außer im Jahr 2023. Im Rahmen der
        individuellen Prognose für Ihre Liegenschaft werden folgende CO<sub>2</sub>-Preise pro Tonne herangezogen:
        <ul>
            <li>2023: 30 EUR pro Tonne CO<sub>2</sub></li>
            <li>2024: 45 EUR pro Tonne CO<sub>2</sub></li>
            <li>2025: 55 EUR pro Tonne CO<sub>2</sub></li>
            <li>2026: 65 EUR pro Tonne CO<sub>2</sub></li>
        </ul>
        Quelle: Brennstoffemissionshandelsgesetz § 10 Absatz 2 Satz 2 Nr. 4 und 5 (Artikel 7 des
        Artikelgesetzes)
        Mehr Informationen zum CO₂-Preis pro Tonne erhalten Sie <a className='red-text'
            href="https://www.techem.com/de/de/news-und-wissen/techem-immo-point/co2-steuer-aufteilung" target="_blank">hier</a>.
      </p>`,
        `<p>Untersuchungen belegen, dass die unterjährige Verbrauchsinformation zu einem 10 % geringeren Energieverbrauch führen kann.
         Dies hilft nicht nur Ihren Mietern, sondern auch Ihnen bei der Reduktion Ihrer CO<sub>2</sub>-Abgabe.
         Bitte beachten Sie, dass die Einsparung von 10% ein Durchschnittswert darstellt, welcher individuell abweichen kann.</p>`,
        `<p>Die Verteilung der CO<sub>2</sub>-Kosten zwischen Mietenden und Vermietenden regelt ein 10-Stufenmodell: 
        Bei Wohnungen mit einer besonders schlechten Energiebilanz übernehmen Vermietende 
        beispielsweise 95% und Mietende 5% der CO<sub>2</sub>-Kosten. Die Einstufung in unserem Rechner wird 
        anhand des CO<sub>2</sub>-Ausstoßes je Quadratmeter vorgenommen. Weitere Informationen zum 10-
        Stufenmodell finden Sie <a className='red-text' href="https://www.techem.com/de/de/news-und-wissen/techem-immo-point/co2-steuer-aufteilung" target="_blank">hier</a>.
        </p>`,
        `Ihren individuellen CO<sub>2</sub>-Emissionsfaktor erfahren Sie von Ihrem Energieversorger. Mit diesem Wert erhalten Sie eine möglichst genaue CO<sub>2</sub>-Kosten-Prognose.
         Denn der Emissionsfaktor von Fernwärme kann je nach den eingesetzten Technologien, dem Energiemix und der lokal erforderlichen Transportnetze stark variieren.`];

    const selectedHeaderText = props.InfoToShow ? modalHeadlineText[props.InfoToShow] : modalHeadlineText[props.currentState]
    const selectedBodyText = props.InfoToShow ? modalBodyText[props.InfoToShow] : modalBodyText[props.currentState]
    return (
        <>
            <img className={`modal-popup ${props.styleName ? props.styleName : ''}`}
                src={hover ? InfoHover : InfoIcon}
                alt='info icon'
                onMouseEnter={activeHover}
                onTouchStart={activeHover}
                onMouseLeave={inactiveHover}
                onTouchCancel={inactiveHover}
                onClick={handleShow} />

            <Modal size='lg' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <div className='container' translate='no'>
                    <Modal.Body className='row p-3'>
                        <Col xs={1} className='p-0 d-flex justify-content-center align-items-start'>
                            <img className='modal-info-icon' src={InfoIcon} alt='info-icon' />
                        </Col>
                        <Col xs={11}>
                            {selectedHeaderText ? (<Modal.Title className='mb-2'>{selectedHeaderText}</Modal.Title>) : ''}
                            {htmlParser(selectedBodyText)}
                        </Col>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="continue" className='rounded-pill' onClick={handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>

            </Modal>
        </>
    );
}

export default InfoModal