import React from 'react';
import './style.css';

const FooterLink = (props) => {
    return (
        <li>
            <a className='footer-link' href={props.link}>{props.text}</a>
        </li>
    )
}

const Footer = () => {
    return (
        <div className="footer">
            <footer role="contentinfo" className="cmp-footer" data-component-name="footer" data-initialized="true">
                <div className="cmp-footer__container">
                    <div className="aem-Grid aem-Grid--12 aem-Grid--default--12">
                        <div className="cmp-footer__logo-wrapper aem-GridColumn aem-GridColumn--phone--12 aem-GridColumn--default--12">
                            <a className="cmp-footer__logo" href='https://www.techem.com/de/de' target="_self">
                                <svg width="93" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.6 60.3">
                                    <g fill="#FFF">
                                        <g>
                                            <path fill="#FFF" d="M0 40.8h69.5a18.22 18.22 0 0 1 7.9 1.8 17.55 17.55 0 0 1 7.6 7.1 17.62 17.62 0 0 1 2.3 5.7 17.5 17.5 0 0
                                         1 .8 4.9 28.59 28.59 0 0 0-6.3-6.6c-4.3-2.8-9.6-3.2-15.9-3.1H10.4a12.37 12.37 0 0 1-3.6-.4A8.25 8.25 0 0 1 3
                                        48a11.24 11.24 0 0 1-2.4-3.7 12.23 12.23 0 0 1-.6-3.5zM178.5 40.8H109a18.22 18.22 0 0 0-7.9 1.8 17.55 17.55 0 0 0-7.6 7.1 17.62
                                         17.62 0 0 0-2.3 5.7 17.5 17.5 0 0 0-.8 4.9 28.59 28.59 0 0 1 6.3-6.6c4.3-2.8 9.6-3.2 15.9-3.1h55.5a12.37 12.37
                                         0 0 0 3.6-.4 8.25 8.25 0 0 0 3.8-2.2 11.24 11.24 0 0 0 2.4-3.7 12.23 12.23 0 0 0 .6-3.5z">
                                            </path>
                                            <path d="M39.5 20.3H30c.1-1.2.1-5.6 4.8-5.6 5.1 0 4.6 4.2 4.7 5.6m8.2 3.7c0-3.2-.1-6-1.2-8.1-1.7-3.4-5-6.4-11.9-6.4-9.5
                                             0-13.1 5.1-13.2 13.7-.1 7.7 5.2 12.9 14.5 12.5 4.2-.2 7.1-1 11.6-4L45.3 28a12.2 12.2 0 0 1-8.4 2.7c-6.6-.4-6.8-5-6.8-6.6zM125
                                             20.3h-9.5c.1-1.2.1-5.6 4.8-5.6 5.1 0 4.5 4.2 4.7 5.6m8.2 3.7c0-3.2-.1-6-1.2-8.1-1.7-3.4-5-6.4-11.9-6.4-9.5 0-13.1 5.1-13.2 13.7-.1
                                             7.7 5.2 12.9 14.5 12.5 4.2-.2 7.1-1 11.6-4l-2.2-3.7a12.2 12.2 0 0 1-8.4 2.7c-6.6-.4-6.8-5-6.8-6.6zM19.5 9.7v5.9h-5.9v9.5c0 3 0 5.3
                                             3.6 5.3a21.05 21.05 0 0 0 2.3-.1v4.2a14 14 0 0 1-6 1.2c-7.4 0-8.8-2.5-8.8-7.2V15.6H0V9.7h4.8V3.8L13.7 0v9.7zM77.8 3.9L87 .1v13.5a10
                                             10 0 0 1 7.6-3.2 9.46 9.46 0 0 1 6.8 2.5c2.4 2.2 2.4 4.9 2.4 8.4v14.4h-9.1V22.4c0-2.1 0-6.1-3.8-6.1a3.86 3.86 0 0 0-3.8 3.4 31.15
                                             31.15 0 0 0-.2 4.8v11.2h-9.1zM136.8 10h8.8v5a8.86 8.86 0 0 1 8.2-5c1.4 0 6 .6 7.5 4.9a10 10 0 0 1 8.5-4.9 8.45 8.45 0 0 1 7.5 4.1c1.3
                                             2.1 1.3 5.1 1.3 8v13.5h-9v-14c0-2.5 0-5.5-3.5-5.5a3.37 3.37 0 0 0-3.3 2.1 7.53 7.53 0 0 0-.5 3.5v13.9h-9.1v-14c0-2.2 0-5.4-3.6-5.4-3.1
                                             0-3.8 2.6-3.8 5.4v14.1h-9V10M67.4 30.5a7.54 7.54 0 0 1-7.6-7.9 7.72 7.72 0 0 1 7.2-8.1c2.4-.2 3.6.2 6 1.8l2-3.5c-1.8-1.2-4.4-3.4-10.9-3.4-8.1
                                             0-13.4 5.5-13.4 13.5 0 6.8 5.3 12.7 13.4 12.7 6.5 0 9.1-2.2 10.9-3.4l-2-3.5c-2.7 1.6-3.3 1.8-5.6 1.8">
                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>

                    <div className="aem-Grid aem-Grid--12 aem-Grid--default--12">
                        <div className="cmp-footer__bottom aem-GridColumn aem-GridColumn--phone--12 no-border">
                            <div className="cmp-footer__left">
                                <ul className="cmp-footer__links">
                                    <FooterLink link='https://www.techem.com/de/de/impressum' text='Impressum' />
                                    <FooterLink link='https://www.techem.com/de/de/datenschutzhinweis' text='Datenschutz' />
                                    <FooterLink link='https://www.techem.com/de/de/unsere-allgemeinen-geschaeftsbedingungen--agbs----techem' text='AGB' />
                                    <FooterLink link='https://www.techem.com/de/de/widerruf' text='Widerruf' />
                                </ul>
                            </div>
                            <div className="cmp-footer__right">
                                <div className="cmp-footer__copyright-container">
                                    <div className="cmp-footer__copyright-statement">
                                        <p className="cmp-footer__copyright">
                                            Techem GmbH
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer