const PageTracker = (state) => {
  const pageIdentifier = `page: ${state + 1}`;
	window.siteDataLayer = window.siteDataLayer || [];
	window.siteDataLayer.push({
		event: 'pageView',
		pageLanguage: 'de',
		pageIdentifier: pageIdentifier, // Optional, but necessary if no virtual page path in url
		pageName: document.title, // document.title
		pagePath: window.location.pathname, // Path w/o query parameters
		pageURL: window.location.href, // Full URL
		virtualPagePath: window.location.hash, // Virtual hash routing path, including #, empty string if 'null/undefined'
	});
};

const SelectionTracker = (selected, state) => {
  const pageIdentifier = `page: ${state + 1}`;
	window.siteDataLayer = window.siteDataLayer || [];
	window.siteDataLayer.push({
		event: 'AnswerSelection',
		pageLanguage: 'de',
		pageIdentifier: pageIdentifier, // Optional, but necessary if no virtual page path in url
		pageName: document.title, // document.title
		pagePath: window.location.pathname, // Path w/o query parameters
		pageURL: window.location.href, // Full URL
		virtualPagePath: window.location.hash, // Virtual hash routing path, including #, empty string if 'null/undefined'
	});
};
export {PageTracker, SelectionTracker};