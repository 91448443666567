import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import './App.css'

import Layout from './components/Layout/Layout';
import { CurrentContextProvider } from './components/Context/Context';

const App = () => {
  if (window.location.href === "https://co2-rechner.azurewebsites.net/" || window.location.href === "https://www.co2-rechner.azurewebsites.net/") {
    window.location.replace("https://co2-rechner.techem.com/")
  }
  return (
    <CurrentContextProvider>
      <Layout></Layout>
    </CurrentContextProvider>
  );
}

export default App;
