import React, { useEffect, useState } from 'react'
import Validator from 'validator'
import { Form, Row, Col, Container } from 'react-bootstrap'
import { BackButton, handleNext } from '../Button/button'
import Chart from '../Chart/Chart'
import { apiUrl } from '../../constants'
import { ContinueButton } from '../Button/button'
import { TotalEmission, getTenantData, getLandlordData, CalculateShare } from '../Calculation/Calculation'
import axios from 'axios'

import IconFlussiggas from '../../assets/icons/IconFlussiggas.svg'
import IconErdgas from '../../assets/icons/IconErdgas.svg'
import IconHeizol from '../../assets/icons/IconHeizol.svg'
import IconFernwarme from '../../assets/icons/IconFernwarme.svg'
import IconHouse from '../../assets/icons/IconHouse.svg'
import IconCloud from '../../assets/icons/IconCloud.svg'
import ImageEmail from '../../assets/images/ImageEmail.png'
import IconEmail from '../../assets/images/EmailRedCircle.svg'

import { useCurrentContext } from '../Context/Context'

const FourthStep = () => {
  const { currentSelected, canContinue, setCanContinue, Consumption, TotalArea, setEmail, fetchedData } = useCurrentContext();
  const vat = currentSelected.val % 2 === 0 ? 1.07 : 1.19;
  const tenantData = getTenantData(currentSelected, Consumption, fetchedData, vat);
  const landlordData = getLandlordData(tenantData, currentSelected, Consumption, TotalArea, fetchedData);
  const CalculateYearlyCost = () => {
    const sum = landlordData.reduce((partial, current) => {
      return parseFloat(partial) + parseFloat(current)
    }, [0])
    return parseFloat(sum).toFixed(2)
  };

  let selectedIcon
  if (currentSelected.val === 1) {
    selectedIcon = IconFlussiggas
  } else if (currentSelected.val === 2) {
    selectedIcon = IconErdgas
  } else if (currentSelected.val === 3) {
    selectedIcon = IconHeizol
  } else if (currentSelected.val === 4) {
    selectedIcon = IconFernwarme
  }

  const userData = {
    "FuelType": currentSelected.name,
    "ConsumptionUnit": Consumption.Unit,
    "ConsumptionValue": Consumption.Value,
    "TotalArea": TotalArea
  }

  useEffect(() => {
    const dataUploadUrl = apiUrl() + '/api/userData'
    axios.post(dataUploadUrl, userData)
      .then(response => {
        console.log(response);
      });
  }, []);
  const [ValidEmail, setValidEmail] = useState(false)
  const [Consent, setConsent] = useState(false)

  const handleEmailChange = (event) => {
    if (Validator.isEmail(event.target.value)) {
      setEmail(event.target.value)
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }

  }

  const handleConsentChange = (event) => {
    if (event.target.checked) {
      setConsent(true)
    } else {
      setConsent(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (canContinue) {
      const triggerClick = handleNext();
      triggerClick();
    }
  };

  useEffect(() => {
    if (ValidEmail && Consent) {
      setCanContinue(true)
    } else {
      setCanContinue(false)
    }
  }, [ValidEmail, Consent])

  return (
    <>
      <Container>
        <Row>
          <Col lg={6}>
            <Row>
              <BackButton />
              <h3 className='col-11 stepper-text text-center'>
                Ihre kostenlose CO<sub>2</sub>-Kostenprognose <br /> ist nur einen Klick entfernt!
              </h3>
            </Row>
            <p className='mt-5 mb-5'>
              Geben Sie einfach Ihre E-Mail-Adresse an und erhalten
              Sie von uns gebührenfrei Ihre individuelle Prognose sowie wertvolle Energiespartipps!
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formInput" className='col-md-8 d-flex flex-column ms-auto me-auto'>
                <Form.Label>E-Mail</Form.Label>
                <Form.Control type="email"
                  placeholder="Bitte hier eingeben" onChange={handleEmailChange} className='mb-5' autoComplete='off' />
              </Form.Group>
              <Form.Group className="mb-3 container" controlId="formBasicCheckbox">
                <Row className='mb-3'>
                  <Col xs={2} sm={1} className='d-flex justify-content-center'>
                    <Form.Check className='consent-check' type="checkbox" onChange={handleConsentChange} />
                  </Col>
                  <Col xs={10} sm={11}>
                    <Form.Label className='consent-text'>Mit dem Absenden dieses Formulars willige ich ein, dass die Techem GmbH und die mit ihr verbundenen Gesellschaften
                      (Techem Energy Services GmbH, Techem Solutions GmbH, Techem X GmbH,
                      bautec Energiemanagment GmbH) die von mir oben eingetragenen Daten für werbliche Ansprachen per E-Mail und/oder der Auswertung zu Marketingzwecken
                      verarbeitet.
                      <br />
                      Diese Einwilligung erfolgt freiwillig und ist mit Wirkung für die Zukunft jederzeit widerrufbar.
                      Einzelheiten zum Datenschutz bei der Techem GmbH entnehmen Sie bitte dem <a href='https://www.techem.com/de/de/datenschutzhinweis' target='_blank'>Datenschutzhinweis</a>.
                    </Form.Label>
                  </Col>
                </Row>
                <div className='col-md-8 d-flex flex-column ms-auto me-auto'>
                  <ContinueButton></ContinueButton>
                </div>
              </Form.Group>
            </Form>
          </Col>
          <Col lg={6} className='ps-4'>
            <div className='email-img-content'>
              <img className='icon-email' src={IconEmail} alt='email-icon'></img>
              <img className='image-email' src={ImageEmail} alt='email-img'></img>
            </div>
          </Col>
        </Row>
      </Container>
      <div id='pdf-section' className='row' translate='no'>
        <div id='chart-section' className='mt-3'>
          <Row className={`mb-3 d-flex`}>
            <Col xs={4} className='ps-0 pe-3'>
              <div className='p-3 d-flex justify-content-between align-items-end'
                style={{ minHeight: '158px' }}>
                <div className='d-flex flex-column align-items-center'>
                  <div className='choice-item'>
                    <img src={selectedIcon} alt='item-icon' />
                    <div className='choice-text'>{currentSelected.name}</div>
                  </div>
                  <div className='univers-bold'>{parseFloat(Consumption.Value).toLocaleString('de-DE')} {Consumption.Unit}</div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                  <div className='choice-item'>
                    <img src={IconHouse} alt='house-icon' />
                    <div className='choice-text'>Wohnfläche</div>
                  </div>
                  <div className='univers-bold'>{TotalArea} m<sup>2</sup></div>
                </div>
              </div>
            </Col>
            <Col xs={3} className='p-0'>
              <div className='p-3 d-flex flex-column justify-content-end align-items-center blue-border-dashed'
                style={{ minHeight: '158px' }}>
                <div className='choice-item'>
                  <img src={IconCloud} alt='house-icon' />
                  <div className='choice-text text-center'>CO<sub>2</sub>-Emission (10 Jahre)</div>
                </div>
                <div className='univers-bold d-flex align-items-end'><div className='d-inline'>{parseFloat(((TotalEmission(currentSelected, Consumption, fetchedData) / 1000) * 10).toFixed(2)).toLocaleString('de-DE')} t</div>
                </div>
              </div>
            </Col>
            <Col xs={5} className='ps-3 pe-0 d-flex'>
              <div className='p-3 d-flex blue-border w-100'
                style={{ minHeight: '158px' }}>
                <div className='d-flex flex-column w-100 justify-content-end'>
                  <div className='stepper-text fs-40 text-end w-100'>{parseFloat(CalculateYearlyCost()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €{(currentSelected.val === 2 || currentSelected.val === 4) ? '*' : ''}</div>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <div className='d-inline univers-bold'>Gesamtkosten</div></div>
                    <div style={{ fontSize: "13px" }}>inkl. MwSt. </div>
                  </div>
                  <div>(10 Jahre)</div>
                </div>
              </div>
            </Col>
          </Row>
          {currentSelected.val === 2 &&
          <Row className='mb-3 pt-3 pb-3 footnote' translate='no' style={{ fontSize: "12px" }}>
            <div>*Es sind CO<sub>2</sub>-Rechner am Markt, die die CO<sub>2</sub>-Menge mit einem heizwertbezogenen CO<sub>2</sub>-Faktor berechnen. Da die Energiemenge Ihrer Versorgerrechnung aber brennwertbezogen errechnet wurde, muss die CO<sub>2</sub>-Menge auch brennwertbezogen errechnet werden. Dies ist in unserem Rechner bereits berücksichtigt. In Rechnern mit einem heizwertbezogenen CO<sub>2</sub>-Faktor müssen Sie daher bitte noch die Energiemenge mit dem Korrekturfaktor 0,903 multiplizieren.</div>
          </Row>
        }

        {currentSelected.val === 4 &&
          <Row className='mb-3 pt-3 pb-3 footnote' translate='no' style={{ fontSize: "12px" }}>
            <div>*Die CO<sub>2</sub>-Kosten haben wir auf Basis der Zertifikatskosten im Europäischen Emissionshandel errechnet. Veröffentlicht vom Umweltbundesamt am 19.01.2024.</div>
          </Row>
        
        }
          <Row className={'mb-3'}>
            <div className='mb-3 d-flex'>
              <Col xs={5}>
                <div className='d-flex flex-column'>
                  <div className='univers-bold fs-22 pe-1'>Techem CO<sub>2</sub>-Kostenprognose:</div>
                  <div>Ihr Anteil beträgt voraussichtlich <span className='d-inline-block'>{parseFloat(CalculateShare(currentSelected, Consumption, TotalArea, 1, fetchedData)).toLocaleString('de-DE')} %
                  </span></div>
                </div>
              </Col>
              <Col xs={7}>
                <Row className='d-flex'>
                  <Col>
                    <Row className='d-flex justify-content-center align-items-center'>
                      <Col xs={1} className='me-3'><span className='dot tenant-dot'></span></Col>
                      <Col xs={3} className='ps-0'><span className='black-text'>Mietende</span></Col>
                    </Row>
                  </Col>
                  <Col className='tenant-text d-flex justify-content-end'>
                    <div>
                      {parseFloat(100 - (CalculateShare(currentSelected, Consumption, TotalArea, 1, fetchedData))).toLocaleString('de-DE')} % Anteil an der CO<sub>2</sub>-Abgabe
                    </div>
                  </Col>

                </Row>
                <Row className='d-flex'>
                  <Col>
                    <Row className='d-flex justify-content-center align-items-center'>
                      <Col xs={1} className='me-3'><span className='dot landlord-dot'></span></Col>
                      <Col xs={3} className='ps-0'><span className='univers-bold black-text'>Vermietende</span></Col>
                    </Row>
                  </Col>
                  <Col className='landlord-text d-flex justify-content-end'>
                    <div>
                      {parseFloat(CalculateShare(currentSelected, Consumption, TotalArea, 1, fetchedData)).toLocaleString('de-DE')} % Anteil an der CO<sub>2</sub>-Abgabe
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
            <div className='d-flex justify-content-lg-center' style={{ "overflowX": "auto" }}>
              <div id='bar-canvas' style={{ "postion": "relative", "minWidth": "90%", "height": "500px" }}>
                <Chart></Chart>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </>
  )
}

export default FourthStep;