import React, { useEffect, useState } from 'react';
import { ButtonGroup, Form } from 'react-bootstrap';
import htmlParser from 'html-react-parser';

import IconFlussiggas from '../../assets/icons/IconFlussiggas.svg'
import IconErdgas from '../../assets/icons/IconErdgas.svg'
import IconHeizol from '../../assets/icons/IconHeizol.svg'
import IconFernwarme from '../../assets/icons/IconFernwarme.svg'

import { useCurrentContext } from '../Context/Context';
import InfoModal from '../Modal/modal';


const SecondStep = () => {
  const { currentState, setCurrentState, currentSelected, setCanContinue, Consumption, setConsumption, setFetchedData } = useCurrentContext();
  const [showEmissionBox, setShowEmissionBox] = useState(false);

  useEffect(() => {
    if (currentSelected.val % 2 === 0) {
      setConsumption({
        ...Consumption,
        'Unit': 'kWh',
      });
    } else {
      setConsumption({
        ...Consumption,
        'Unit': 'L',
      });
    }
  }, []);

  useEffect(() => {
    if (Consumption.Value) {
      setCanContinue(true);
    }
  }, [Consumption]);

  const handleInputChange = (event) => {
    setConsumption({
      ...Consumption,
      'Value': event.target.value,
    });
  }

  const handleBrennwertChange = (event) => {
    setFetchedData({
      "Erdgas":
        { "Unit": "kWh", "EmissionFactor": 0.18139, "Heizwert": 1, "Brennwert": 1.11 },
      "Flüssiggas":
        { "Unit": "L", "EmissionFactor": 0.2358, "Heizwert": 6.57, "Brennwert": 1 },
      "Heizöl":
        { "Unit": "L", "EmissionFactor": 0.2664, "Heizwert": 10.0461, "Brennwert": 1 },
      "Fernwärme":
        { "Unit": "kWh", "EmissionFactor": event.target.value ? parseFloat(event.target.value) : 0.2553, "Heizwert": 1, "Brennwert": 1 }
    });
  }

  const handleSubmit = e => {
    e.preventDefault();
    setCurrentState(currentState + 1)
    setCanContinue(false)
  };


  let selectedIcon;
  let addPadding = false;

  const selectedName = currentSelected.name;

  if (currentSelected.val === 1) {
    selectedIcon = IconFlussiggas
  } else if (currentSelected.val === 2) {
    selectedIcon = IconErdgas
    addPadding = true
  } else if (currentSelected.val === 3) {
    selectedIcon = IconHeizol
  } else if (currentSelected.val === 4) {
    selectedIcon = IconFernwarme
  }

  const handleSwitchChange = () => {
    setShowEmissionBox(!showEmissionBox)
    if (showEmissionBox === true) {
      setFetchedData({
        "Erdgas":
          { "Unit": "kWh", "EmissionFactor": 0.18139, "Heizwert": 1, "Brennwert": 1.11 },
        "Flüssiggas":
          { "Unit": "L", "EmissionFactor": 0.2358, "Heizwert": 6.57, "Brennwert": 1 },
        "Heizöl":
          { "Unit": "L", "EmissionFactor": 0.2664, "Heizwert": 10.0461, "Brennwert": 1 },
        "Fernwärme":
          { "Unit": "kWh", "EmissionFactor": 0.2553, "Heizwert": 1, "Brennwert": 1 }
      });
    }
  }

  const emissionLabelText = `<div>
  <a className='red-text' href='https://www.umweltbundesamt.de/publikationen/emissionsbilanz-erneuerbarer-energietraeger-2020#:~:text=Bestimmung%20der%20vermiedenen%20Emissionen%20im%20Jahr%202020&text=Im%20Ergebnis%20weist%20die%20Netto,den%20Stromsektor%20entfielen%20179%20Mio' target="_blank" rel="noopener noreferrer">
    Bundesweiten ⌀ CO<sub>2</sub> -Emissionsfaktor <br/> 255,3 g/kWh verwenden.</a></div>`;

  return (
    <div className='d-flex align-items-center mb-5 choice-container'>
      <div className={`grid-row${selectedName === 'Fernwärme' ? ' align-items-start block-4' : ''}`}>
        <div>
          <div className='choice-item'>
            <img src={selectedIcon} alt='item-icon' />
            <p className='choice-text mt-2 mb-0'>{ }</p>
          </div>
          {selectedName === 'Fernwärme' &&
            <div className='d-inline-flex'>
              Emissionfaktor <InfoModal currentState={currentState} InfoToShow={7}></InfoModal>
            </div>
          }
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='d-flex align-items-center position-relative' controlId="formInput">
            <Form.Control type="number" placeholder="Wert eintragen" className={`pt-2 ${addPadding ? 'pe-5' : 'pe-4'}`}
              onChange={handleInputChange}
              value={Consumption.Value}
              autoComplete='off' />
            {selectedName === 'Erdgas' &&
              <span className='unit-kwh'>kWh</span>
            }
            {(selectedName === 'Flüssiggas' || selectedName === 'Heizöl') &&
              <span className='unit'>L</span>
            }
            {selectedName === 'Fernwärme' &&
              <span className='unit-kwh'>kWh</span>
            }
          </Form.Group>
          {selectedName === 'Fernwärme' &&
            <Form.Group className='mt-3'>
              <Form.Check
                type="switch"
                label={htmlParser(emissionLabelText)}
                onChange={handleSwitchChange}
                checked={!showEmissionBox}
              />
              {showEmissionBox &&
                <div className='d-flex align-items-center position-relative mt-3'>
                  <Form.Control type='number' placeholder='CO₂-Emissionsfaktor' onChange={handleBrennwertChange} autoComplete='off' className={`pt-2 ${addPadding ? 'pe-5' : 'pe-4'}`}>
                  </Form.Control>
                  <span className='unit-g-kwh'>g/kWh</span>
                </div>
              }
            </Form.Group>
          }
        </Form>
      </div>
    </div>
  )

}

export default SecondStep;