// const fetchedData = {
//   "Erdgas":
//     [{ "Unit": "kWh", "EmissionFactor": 200.9, "Heizwert": 1, "Brennwert": 1.11 },
//     { "Unit": "m3", "EmissionFactor": 200.9, "Heizwert": 10, "Brennwert": 1 }],
//   "Flüssiggas":
//     { "Unit": "L", "EmissionFactor": 235.8, "Heizwert": 6.57, "Brennwert": 1 },
//   "Heizöl":
//     { "Unit": "L", "EmissionFactor": 266.4, "Heizwert": 10, "Brennwert": 1 },
//   "Fernwärme":
//     { "Unit": "kWh", "EmissionFactor": 255.3, "Heizwert": 1, "Brennwert": 1 }
// };

export const InitialPricePerTon = 30;

export const TotalEmission = (currentSelected, Consumption, fetchedData) => {
  switch (currentSelected.name) {
    case 'Erdgas':
      return CalculateTotalEmission(fetchedData.Erdgas, Consumption);
    case 'Flüssiggas':
      return CalculateTotalEmission(fetchedData.Flüssiggas, Consumption);
    case 'Heizöl':
      return CalculateTotalEmission(fetchedData.Heizöl, Consumption);
    case 'Fernwärme':
      return CalculateTotalEmission(fetchedData.Fernwärme, Consumption);
    default:
      return
  }
}

const CalculateTotalEmission = (selectedData, Consumption) => {
  return ((((parseInt(Consumption.Value)
    * selectedData.Heizwert))
    * selectedData.EmissionFactor))
}

export const CalculateShare = (currentSelected, Consumption, TotalArea, reduction, fetchedData) => {
  const perMeterEmission = TotalEmission(currentSelected, Consumption, fetchedData) * reduction / TotalArea
  switch (true) {
    case (perMeterEmission < 12):
      return 0
    case (12 <= perMeterEmission && perMeterEmission < 17):
      return 10
    case (17 <= perMeterEmission && perMeterEmission < 22):
      return 20
    case (22 <= perMeterEmission && perMeterEmission < 27):
      return 30
    case (27 <= perMeterEmission && perMeterEmission < 32):
      return 40
    case 32 <= perMeterEmission && perMeterEmission < 37:
      return 50
    case 37 <= perMeterEmission && perMeterEmission < 42:
      return 60
    case 42 <= perMeterEmission && perMeterEmission < 47:
      return 70
    case 47 <= perMeterEmission && perMeterEmission < 52:
      return 80
    case perMeterEmission >= 52:
      return 95
    default:
      return 0
  }
};

export const labels = ['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032'];

export const getTenantData = (currentSelected, Consumption, fetchedData, vat) => {
  // vat 1.07 only applies for 2023
  return labels.map((item, index) => {
    if (currentSelected.name === 'Fernwärme') {
      if (index === 0) {
        return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) / 1000 * 83.68 * 1.07 ).toFixed(2)
      } else {
        return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) / 1000 * 83.68 * 1.19).toFixed(2)
      }
    } else {
      if (index === 0) {
        return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) / 1000 * InitialPricePerTon * vat).toFixed(2)
      } else if (index === 1) {
        return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) / 1000 * (InitialPricePerTon + 15) * 1.19).toFixed(2)
      } else if (index === 2) {
        return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) / 1000 * (InitialPricePerTon + 25) * 1.19).toFixed(2)
      } else {
        return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) / 1000 * (InitialPricePerTon + 35) * 1.19).toFixed(2)
      }
    }
  });
};

export const getDiscountedTenantData = (currentSelected, Consumption, fetchedData, vat) => {
  return labels.map((item, index) => {
    if (index === 0) {
      return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) * 0.9 / 1000 * InitialPricePerTon * vat).toFixed(2)
    } else if (index === 1) {
      return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) * 0.9 / 1000 * (InitialPricePerTon + 15) * 1.19).toFixed(2)
    } else if (index === 2) {
      return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) * 0.9 / 1000 * (InitialPricePerTon + 25) * 1.19).toFixed(2)
    } else {
      return parseFloat(TotalEmission(currentSelected, Consumption, fetchedData) * 0.9 / 1000 * (InitialPricePerTon + 35) * 1.19).toFixed(2)
    }
  });
};

export const getLandlordData = (tenantData, currentSelected, Consumption, TotalArea, fetchedData) => {
  return tenantData.map((item) => parseFloat((item * (CalculateShare(currentSelected, Consumption, TotalArea, 1, fetchedData) / 100))).toFixed(2));
};

export const getDiscountedLandlordData = (discountedTenantData, currentSelected, Consumption, TotalArea, fetchedData) => {
  return discountedTenantData.map((item) => parseFloat((item * (CalculateShare(currentSelected, Consumption, TotalArea, 0.9, fetchedData) / 100))).toFixed(2));
};
