import React, { useEffect } from 'react';
import { useCurrentContext } from '../Context/Context';
import { BackButton } from '../Button/button';
import ReportSection from '../ReportSection/ReportSection';

const FifthStep = () => {
  const { setCanContinue } = useCurrentContext();
  useEffect(() => {
    setCanContinue(true);
  }, []);
  return (
    <>
      <div className='d-flex w-100 mt-3 mb-3'>
        <BackButton />
      </div>
      <ReportSection></ReportSection>
    </>
  )
}

export default FifthStep;