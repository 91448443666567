export const apiUrl = function () {
  if (window.location.host === 'co2-rechner.azurewebsites.net'
    || window.location.host === 'co2-rechner.techem.com') {
    return 'https://co2-server.azurewebsites.net';
  } else {
    return 'https://co2-rechner-server-dev.azurewebsites.net';
  }
};

export const formId = function () {
  if (window.location.host === 'co2-rechner.azurewebsites.net'
    || window.location.host === 'co2-rechner-rel.azurewebsites.net'
    || window.location.host === 'co2-rechner.techem.com'
    || window.location.host === 'co2-rechner-rel.techem.com') {
    return 1609;
  } else {
    return 1241;
  }
}